import {createGlobalStyle, withTheme} from "styled-components";
import fontFiles from "./Fonts";
import {media} from "utils/Media"

const GlobalStyles = createGlobalStyle`

    @font-face {
        font-family: "Futura PT W01 Bold";
        src: url(${fontFiles.FuturaPTW01BoldEOT});
        src:
            url(${fontFiles.FuturaPTW01BoldEOT}) format("eot"), 
            url(${fontFiles.FuturaPTW01BoldWOFF2}) format("woff2"), 
            url(${fontFiles.FuturaPTW01BoldWOFF}) format("woff"),
            url(${fontFiles.FuturaPTW01BoldTTF}) format("truetype");
        font-display: swap;
    }
    
    @font-face {
        font-family: "Proxima Nova W05 Light";
        src: url(${fontFiles.ProximaNovaW05LightEOT});
        src:
            url(${fontFiles.ProximaNovaW05LightEOT}) format("eot"), 
            url(${fontFiles.ProximaNovaW05LightWOFF2}) format("woff2"), 
            url(${fontFiles.ProximaNovaW05LightWOFF}) format("woff"),
            url(${fontFiles.ProximaNovaW05LightTTF}) format("truetype");
        font-display: swap;
    }

    @font-face {
        font-family: "Proxima Nova W05 Regular";
        src: url(${fontFiles.ProximaNovaW05RegularEOT});
        src:
            url(${fontFiles.ProximaNovaW05RegularEOT}) format("eot"), 
            url(${fontFiles.ProximaNovaW05RegularWOFF2}) format("woff2"), 
            url(${fontFiles.ProximaNovaW05RegularWOFF}) format("woff"),
            url(${fontFiles.ProximaNovaW05RegularTTF}) format("truetype");
        font-display: swap;
    }

    @font-face {
        font-family: "Proxima Nova W05 Medium";
        src: url(${fontFiles.ProximaNovaW05MediumEOT});
        src:
            url(${fontFiles.ProximaNovaW05MediumEOT}) format("eot"), 
            url(${fontFiles.ProximaNovaW05MediumWOFF2}) format("woff2"), 
            url(${fontFiles.ProximaNovaW05MediumWOFF}) format("woff"),
            url(${fontFiles.ProximaNovaW05MediumTTF}) format("truetype");
        font-display: swap;
    }
    
    @font-face {
        font-family: "Proxima Nova W01 Semibold";
        src: url(${fontFiles.ProximaNovaW01SemiBoldEOT});
        src:
            url(${fontFiles.ProximaNovaW01SemiBoldEOT}) format("eot"), 
            url(${fontFiles.ProximaNovaW01SemiBoldWOFF2}) format("woff2"), 
            url(${fontFiles.ProximaNovaW01SemiBoldWOFF}) format("woff"),
            url(${fontFiles.ProximaNovaW01SemiBoldTTF}) format("truetype");
        font-display: swap;
    }

    @font-face {
        font-family: "Proxima Nova W05 Bold";
        src: url(${fontFiles.ProximaNovaW05BoldEOT});
        src:
            url(${fontFiles.ProximaNovaW05BoldEOT}) format("eot"), 
            url(${fontFiles.ProximaNovaW05BoldWOFF2}) format("woff2"), 
            url(${fontFiles.ProximaNovaW05BoldWOFF}) format("woff"),
            url(${fontFiles.ProximaNovaW05BoldTTF}) format("truetype");
        font-display: swap;
    }

    @font-face {
        font-family: "noto_sansbold";
        src:
            url(${fontFiles.noto_sansboldWOFF2}) format("woff2"), 
            url(${fontFiles.noto_sansboldWOFF}) format("woff"),
            url(${fontFiles.noto_sansboldTTF}) format("truetype");
        font-display: swap;
    }

    @font-face {
        font-family: "noto_sansregular";
        src:
            url(${fontFiles.noto_sansregularWOFF2}) format("woff2"), 
            url(${fontFiles.noto_sansregularWOFF}) format("woff"),
            url(${fontFiles.noto_sansregularTTF}) format("truetype");
        font-display: swap;
    }

    @font-face {
        font-family:"Turquoise W05 Inline";
        src: url(${fontFiles.TurquoiseW05InlineEOT});
        src: 
            url(${fontFiles.TurquoiseW05InlineEOT}) format("eot"),
            url(${fontFiles.TurquoiseW05InlineWOFF2}) format("woff2"),
            url(${fontFiles.TurquoiseW05InlineWOFF}) format("woff"),
            url(${fontFiles.TurquoiseW05InlineTTF}) format("truetype");
        font-display: swap;
    }

    body {
        font-family: ${props => props.theme.font.family.body};
        font-size: ${props => props.theme.font.size.base};
        font-weight: normal;
        line-height: ${props => props.theme.font.lineHeight.base};
        color: ${props => props.theme.colors.greyDark};
        -webkit-font-smoothing: antialiased;
        overflow-x: hidden;
    }
    
    h1,
    h2, 
    h3 {
        font-family: ${props => props.theme.font.family.light} !important;
        font-weight: 300;
        margin-bottom: 1rem;
    }
    
    h4, 
    h5, 
    h6 {
        font-family: ${props => props.theme.font.family.base};
        font-weight: normal;
    }

    h1 {
        font-size: ${props => props.theme.font.h1.sm.size};
        line-height: ${props => props.theme.font.h1.sm.lineHeight};
        
        @media ${media.md} {
            font-size: ${props => props.theme.font.h1.md.size};
            line-height: ${props => props.theme.font.h1.md.lineHeight};
        }
        
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h1.size};
            line-height: ${props => props.theme.font.h1.lineHeight};
        }
    }
    h2 {
        font-size: ${props => props.theme.font.h2.xs.size} !important;
        line-height: ${props => props.theme.font.h2.xs.lineHeight};
        letter-spacing: 0.03em;
        
        @media ${media.md} {
            font-size: ${props => props.theme.font.h2.size} !important;
            line-height: ${props => props.theme.font.h2.lineHeight};
        }
    }
    h3 {
        font-size: ${props => props.theme.font.h3.xs.size};
        line-height: ${props => props.theme.font.h3.xs.lineHeight};
        
        @media ${media.sm} {
            font-size: ${props => props.theme.font.h3.sm.size};
            line-height: ${props => props.theme.font.h3.sm.lineHeight};
        }
        
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h3.size};
            line-height: ${props => props.theme.font.h3.lineHeight};
        }
    }
    h4 {
        font-size: ${props => props.theme.font.h4.sm.size};
        line-height: ${props => props.theme.font.h4.sm.lineHeight};
        
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h4.size};
            line-height: ${props => props.theme.font.h4.lineHeight};
        }
    }
    h5 {
        font-size: ${props => props.theme.font.h5.sm.size};
        line-height: ${props => props.theme.font.h5.sm.lineHeight};
        
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h5.size};
            line-height: ${props => props.theme.font.h5.lineHeight};
        }
    }
    h6 {
        font-size: ${props => props.theme.font.h6.sm.size};
        line-height: ${props => props.theme.font.h6.sm.lineHeight};
        
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h6.size};
            line-height: ${props => props.theme.font.h6.lineHeight};
        }
    }

    a {
        color: ${props => props.theme.colors.black};
        &:hover {
            color: ${props => props.theme.colors.black};  
        }
    }
    
    p {
        font-size: ${props => props.theme.font.p.size};
        line-height: ${props => props.theme.font.p.lineHeight};
        font-family: ${props => props.theme.font.family.light};
        font-weight: 300;
        
        &.bigger {
            font-size: ${props => props.theme.font.p.bigger.size};
            line-height: ${props => props.theme.font.p.bigger.lineHeight};
        }
        
        &.bold {
            font-family: ${props => props.theme.font.family.bold};
            font-weight: bold;
        }
    }

    ul {
        padding-left: 20px;
    }

    img {
        max-width: 100%;
    }
`;

export default withTheme(GlobalStyles);