// import { media } from "utils/Media"
// match bootstrap sizes, also add xxl
const sizes = {
    xs: "0px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
    disney: "1025px"
};

const fontSizeBase = 1;

const font = {
    family: {
        futuraBold: `Futura PT W01 Bold, Helvetica, Arial, sans-serif`,
        disneyRegular: `"noto_sansregular", Helvetica, Arial, sans-serif`,
        disneyBold: `"noto_sansbold", Helvetica, Arial, sans-serif`,
        special: `Turquoise W05 Inline, Helvetica, Arial, sans-serif`,
        light: "Proxima Nova W05 Light, Helvetica, Arial, sans-serif",
        base: "Proxima Nova W05 Regular, Helvetica, Arial, sans-serif",
        medium: "Proxima Nova W05 Medium, Helvetica, Arial, sans-serif",
        semiBold: "Proxima Nova W01 Semibold, Helvetica, Arial, sans-serif",
        bold: "Proxima Nova W05 Bold, Helvetica, Arial, sans-serif",
        body: `"Proxima Nova W05 Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
    },
    size: {
        base: `${fontSizeBase * .875}rem`, // 14px
        sm: `${fontSizeBase * .75}rem`, // 12px
        md: `${fontSizeBase}rem`, // 16px
        lg: `${fontSizeBase * 1.25}rem`, // 20px
        xl: `${fontSizeBase * 1.5}rem` // 24px
    },
    lineHeight: {
        sm: 1.1,
        base: 1.4,
        headings: 1.2
    },
    h1: {
        size: `${fontSizeBase * 3}rem`,
        lineHeight: `100%`,
        md: {
            size: `${fontSizeBase * 2}rem`,
            lineHeight: `100%`,
        },
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `100%`,
        }
    },
    h2: {
        size: `${fontSizeBase * 1.5}rem`,
        lineHeight: `100%`,
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.5}rem`,
        },
        xs: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.5}rem`,
        },
    },
    h3: {
        size: `${fontSizeBase * 1.5}rem`,
        lineHeight: `100%`,
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.5}rem`,
        },
        xs: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.5}rem`,
        },
    },
    h4: {
        size: `${fontSizeBase * 1.5}rem`,
        lineHeight: `${fontSizeBase * 1.8125}rem`,
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.25}rem`,
        }
    },
    h5: {
        size: `${fontSizeBase * 1.25}rem`,
        lineHeight: `${fontSizeBase * 1.5}rem`,
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.375}rem`,
        }
    },
    h6: {
        size: `${fontSizeBase * 1.25}rem`,
        lineHeight: `${fontSizeBase * 1.5}rem`,
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.375}rem`,
        }
    },
    p: {
        size: `${fontSizeBase * 0.875}rem`,
        lineHeight: `150%`,
        bigger: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `130%`,
        }
    }
}

export default {
    sizes,
    font,
    transitionBase: "all .4s ease-in-out",
    colors: {
        white: "#ffffff",
        black: "#000000",
        superLightGrey: "#FAFAFA",
        lightGrey: "#F2F2F2",
        grey: "#E4E4E4",
        darkGrey: "#CCCCCC",
        red: "#C12518",
        pink: "#C9599C",
        orange: "#E78239",
        yellow: "#F3CE12",
        purple: "#A57CFC",
        blue: "#7995FA",
        darkBlue: "#012D78",
        green: "#89C973",
        brown: "#6E1700"
    }
};